.company-font {
	font-size: 1.2rem;
}

.top_nav {
  background: $primary;
	padding-left: 15px;
	padding-right: 15px;

	.name {
		color: white;
		text-decoration: none;
	}

	.dropdown-toggle {
		color: white;
	}

	.nav-link {
		&:link,
		&:visited {
			color: white;
		}

		font-size: 18px;
		text-align: center;

		svg {
			font-size: 28px;
		}
	}

	.list-group-item {
		background-color: transparent;
	}
}

.dropdown-item {
	color: black;

	&:hover, &:active, &:focus {
		text-decoration: none;
		color: black;
		background-color: #f8f9fa;
	}
}

.notification-dot {
	position: absolute;
	top: -7px;
    left: 38px;
}
