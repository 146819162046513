.flexible-modal {
  position: fixed; //absolute;
  z-index: 1;
  border: 1px solid #ccc;
  background: white;
  height: auto !important;
}

.flexible-modal-mask {
  position: fixed;
  height: 100%;
  // background: rgba(55, 55, 55, 0.1);
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 1;
}

.flexible-modal-resizer {
  position:absolute;
  right:0;
  bottom:0;
  cursor:se-resize;
  margin:5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}

.flexible-modal-drag-area{
  // background: rgba(54, 127, 199, 0.22);
  height: 40px;
  position:absolute;
  left:0;
  top:0;
  cursor:move;
}

.flexible-modal-drag-area-left,
.flexible-modal-drag-area-right,
.flexible-modal-drag-area-bottom {
  display: none;
}
