/* General */
$screen-xl-min: 1400px;
$screen-lg-min: 992px;
$screen-md-min: 768px;
$screen-sm-min: 576px;
$screen-xs-min: 0;

/* Colors */
$primary: $info;
$primary-green: #076f80;
$secondary: #6c757d;
$primary-hover: $secondary;

$grey: #ececec;
$grey-hover: #e3e1e1;

$grey-light: #f3f3f3;

$color_p: black;

$bg-color: #ecf4e7;

$border-color: #ced4da;

$left-nav-width: 230px;

$read-blue: #3e92fb;

$highlight: #dafbff;

$oneColor: #e68e21;
$oneBgColor: #fdf5e4;

$nav_height: 77px;
