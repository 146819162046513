.message-body {
	// background: #ECE5DD;
	position: relative;
	min-height: 100px;
}

.msg{
	width:100%;
	height:auto;
	display:block;
	overflow:hidden;

	.bubble{
		float:left;
		max-width: 75%;
		width: auto;
		height: auto;
		display: block;
		background: #f1f1f1;
		border-radius: 5px;
		position: relative;
		margin: 10px 0 3px 10px;
		box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);


		&.alt{
			margin: 10px 10px 3px 0px;
			background:#DCF8C6;
			float:right;
		}

		&.has-unread {
			margin-bottom: 20px;
		}

		&.follow{
			margin: 2px 0 3px 25px;
		}

		&.altfollow{
			margin: 2px 25px 3px 0px;
			background:#DCF8C6;
			float:right;
		}

		.txt{
			padding: 8px 0 8px 0;
			width:100%;

			.name{
				font-weight: 600;
				font-size: 14px;
				display: inline-table;
				padding: 0 0 0 15px;
				margin: 0 0 4px 0;
				color: #3498db;
				span{
					font-weight: normal;
					color: #b3b3b3;
					overflow: hidden;
				}
				&.alt{
					color: #2ecc51;
				}
			}
			.message{
				font-size: 15px;
				padding: 0 15px 0 15px;
				// margin: auto;
				color: #2b2b2b;
				display: table;
			}
			.timestamp{
				font-size: 14px;
				margin: auto;
				padding: 0px 15px 0 64px;
				display: table;
				float:right;
				position: relative;
				text-transform: uppercase;
				color: #999
			}
		}

		.reply-button {
			padding: 2px 5px;

		}

		.bubble-arrow {
			position: absolute;
			float:left;
			left: -11px;
			top: 0px;
			&.alt{
				bottom: 20px;
				left: auto;
				right: 4px;
				float:right;
			}
		}
		.bubble-arrow:after {
			content: "";
			position: absolute;
			border-top: 15px solid #f1f1f1;
			border-left: 15px solid transparent;
			border-radius: 4px 0 0 0px;
			width: 0;
			height: 0;
			//box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
			//looks like I can't give a shadow to the arrows
		}
		.bubble-arrow.alt:after {
			border-top: 15px solid #DCF8C6;
			transform: scaleX(-1);
		}

		.unread {
			position: absolute;
			bottom: -21px;
			right: 3px;
			z-index: 10;
			color: grey;
			font-size: 12px;
		}
	}
}

@media only screen and (max-width: 450px) {

	.container{
		width: 100%;
	}
	.timestamp {
		display: none;
		color: red;
	}
}
