.page-login {
  padding-top: 17vh;
  padding-bottom: 10vh;
  background-color: #f7f7f7;
  min-height: 100vh;

  .card {
    margin: 0 auto;
  }
}
