.loading-wrapper {
	background-color: rgba(0, 0, 0, 0.3);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1070;

	&.absolute {
		position: absolute;
	}
}

.loading__icon {
	position: absolute;
	left: calc(50% - 32px);
	top: calc(50% - 24px);
	color: white;
}
