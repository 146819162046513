.css-1rhbuit-multiValue {
	background-color: $grey-light !important;
	border: 1px solid $border-color;
}

.select-control {
	&.invalid div {
		border-color: $danger !important;
	}

	div {
		border-radius: 0 !important;
	}
}
