.items-container {
	border-top: 1px solid $border-color;
	border-left: 1px solid $border-color;
	border-right: 1px solid $border-color;
	border-bottom: 1px solid $border-color;
}

.accordion {
	.card:last-child {
		border-bottom-width: 0;
	}

	.card-header {
    cursor: pointer;
	}
}

.float-right-btn {
	position: absolute;
  right: 10px;
  top: 7px;
}

.edit-product-image {
	max-height: 120px;
}

.delete-image-btn {
	position: absolute;
	right: -8px;
	top: -8px;
}

.sticky-bottom {
	position: sticky;
  bottom: 0;
  background-color: white;
  padding: 14px;
	border-top: 1px solid $border-color;
}
