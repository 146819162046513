.home-title-bar {
  background-image: url('/images/blue-watercolor-background.jpg');
  background-position: center;
  background-size: cover;
  padding: 100px 0;
  color: white;
}

.home-card {
  background-color: white;
  border: 1px solid #e0e0e0;
  padding: 30px 0;
  margin-bottom: 30px;

  &:hover {
    box-shadow: 1px 1px 8px 0 #cccccc;
  }
}

.badge-inside-talk {
  .badge {
    top: 23%;
    font-size: 30px;
    margin: auto;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 76px;
  }

  .badge-pill {
    padding-left: 0;
    padding-right: 0;
  }
}
