input[type=search]::placeholder {
	color: $grey-light;
}

.card {
	border-radius: 0;
}

.form-control {
	border-radius: 0;

	&--inline {
		display: inline-block;
		width: auto;
		margin-right: 10px;
	}

	&--short {
		width: 45px;
	}

	&--medium {
		width: 70px;
	}

	&.disabled,
  &:disabled {
		border: 1px solid $border-color;
		background-color: $grey-light;
		color: grey;
	}

	&::placeholder {
	  opacity: 0.6;
	}
}

select.form-control {
	padding-left: 5px;
}

select.disabled {
	appearance:none;
}

.form-check {
	display: inline-block;
	margin-right: 20px;
	margin-top: 7px;
}

input[type=checkbox] {
	height: 18px;
	width: 18px;
}

label.disabled,
p.disabled,
.disabled {
	color: #7b7b7b;
}

.form-check-label {
	display: inline;
}

.small .form-check-input {
	margin-top: 0;
}

.inactive,
.inactive p {
	color: #808080;
}

textarea.disabled {
	border: 1px solid #ced4da;
	background-color: #f3f3f3;
	color: grey;
}
