.fc-event, .fc-event-dot {
	background-color: transparent;
}

.fc-event {
	border-width: 0;
}

.fc-day-number {
	border-radius: 50%;
	padding: 0;
	font-size: 14px;
	height: 22px;
	width: 22px;
	text-align: center;
}

.fc-unthemed td.fc-today {
	background-color: transparent;

	.fc-day-number {
		background-color: $primary;
		color: white;
	}
}


.fc-day-grid-event {
  padding-left: 5px;

	.fc-content {
		white-space: normal !important;
	}
}

.change-price-popover {
	.change-price-popover--header {
		background-color: $primary;
		color: white;
		padding: 4px 9px;
	}
}

.fc-list-item-time {
    display: none;
}

.fc-sun{
	.fc-day-number {
		color: red;
	}
}
