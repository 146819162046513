// .nav-tabs .nav-link {
// 	border-radius: 0;
// 	border-color: $border-color $border-color #fff;
// 	box-shadow: 3px -2px 3px 0px rgba(241, 241, 241, 0.6);
// 	height: 100%;
// 	text-align: left;
//   background-color: #f9f9f9;
// 	border-bottom: 1px solid #e9ecef ;
//
// 	&.active,
// 	&:hover {
// 		background-color: white;
// 	}
// }
.vertical-tab-menu {
	.list-group-item:first-child {
		border-top-right-radius: 0;
	}
	.list-group-item:last-child {
		border-bottom-right-radius: 0;
	}
	.list-group-item {
		&.active {
	    background-color: $primary;
	    border-color: $primary;
			color: white;
			outline:0;
			z-index: 2;

			&::after {
				content: '';
		    position: absolute;
		    left: 100%;
		    top: 50%;
		    margin-top: -13px;
		    border-bottom: 13px solid transparent;
		    border-top: 13px solid transparent;
				border-left: 10px solid $primary;
			}
		}
	}
}

.item-nav {
	.list-group-item {
		&.active {
	    background-color: $primary;
	    border-color: $primary;
			color: white;
			outline:0;
			z-index: 2;

			&:not(:last-child):after {
					content: '';
			    position: absolute;
					left: 100%;
					top: 0%;
					border-bottom: 25px solid transparent;
			    border-top: 25px solid transparent;
			    border-left: 22px solid $primary;
			}
		}

		// &:not(:last-child):after {
		// 		content: '';
		//     position: absolute;
		// 		left: 100%;
		// 		top: 0%;
		// 		border-bottom: 25px solid transparent;
		//     border-top: 25px solid transparent;
		//     border-left: 22px solid $primary;
		// }
	}
}
