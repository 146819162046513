.delete-row {
	position: absolute;
  left: 0;
  width: 100%;
  padding: 13px 0;
  background-color: #213a5066;
}

.read-blue {
  color: $read-blue;
}


.service-status-KK,
.service-status-KK:focus,
.service-status-KK:disabled {
  background-color: $success;
  color: white;
}

.service-status-UC,
.service-status-UC:focus,
.service-status-UC:disabled {
  background-color: $danger;
  color: white;
}

.service-status-XX,
.service-status-XX:focus,
.service-status-RE,
.service-status-RE:focus,
.service-status-RQ,
.service-status-RQ:focus,
.service-status-XX:disabled,
.service-status-RE:disabled,
.service-status-RQ:disabled {
  background-color: $warning;
}
